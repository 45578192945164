
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { TODAY } from "@/core/data/events";
import { ContentLoader } from "vue-content-loader";
import { Modal } from "bootstrap";
import moment from "moment";
import { getBaseAgendamento, getBaseAgendamentoEntrega } from "@/services/AgendaService";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import useEmitter from "@/composables/Emmiter";
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import VisualizarAgendamentoModal from "@/views/agenda-virtual/components/modais/agendamentos/VisualizarAgendamentoModal.vue";
import { removeModalBackdrop } from "@/core/helpers/dom";
import useUtilChanges from "@/composables/UtilChanges";
import { changeStoppedDayUpdate } from "@/views/agenda-virtual/utils/changeStopDayUtils";

export default defineComponent({
  name: "agenda-virtual-exibir-consultor-hyundai",
  components: {
    FullCalendar,
    ContentLoader,
    ElConfigProvider,
    VisualizarAgendamentoModal,
  },

  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const { replacePlaca, cutChassi } = useUtilChanges();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const concessionariaInfo: any = ref({});
    const refCalendar: any = ref(null);
    const loading: any = ref(false);
    const resources: any = ref([]);
    const consultores: any = ref([]);
    const eventInfo: any = ref();
    let events: any = ref([]);
    let refRegexMarca: any = ref("");
    const isUserActive = ref<any>(false);
    const agendamentosEntreguesConflitantes: any = ref([]);
    const cacheVisibleTime = window.localStorage.getItem("agendamentoVisibleType");
    const visibleTime: any = ref(cacheVisibleTime ?? "1");
    const cacheAgendamentosEntregues: any = ref([]);
    const cacheAgendamentos: any = ref([]);
    const dataMesAtual = moment().startOf("day").toISOString();
    const dateCalendarFilter = ref(dataMesAtual);
    const timeNow = ref(moment().format("HH:mm:ss"));
    const auxLastGetAgendamentoEntrega = ref();
    const horaAbertura = ref("08:00");
    const horaFechamento = ref("19:00");
    const countClickPrevNext = ref(0);

    async function horariosQuadro() {
      const concessionaria = store.getters.concessionariaSelectedInfo;

      if (!(Object.keys(concessionaria).length === 0)) {
        horaAbertura.value = concessionaria.horaAbertura;
        horaFechamento.value = concessionaria.horaFechamento;

        calendarOptions.value.slotMinTime = moment(
          concessionaria.horaAbertura,
          "H"
        ).format("HH:mm:ss");
        calendarOptions.value.businessHours.startTime = moment(
          concessionaria.horaAbertura,
          "H"
        ).format("HH:mm");
        calendarOptions.value.businessHours.endTime = moment(
          concessionaria.horaFechamento,
          "H"
        ).format("HH:20");
      }
    }

    const refStatus = [
      {
        nome: "Agendado",
        cor: "#F2B208",
      },
      {
        nome: "Atrasado",
        cor: "#d20000",
      },
      {
        nome: "Recebido",
        cor: "#008000",
      },
    ];
    const calendarOptions = ref({
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      plugins: [
        resourceTimelinePlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
        resourceTimeGridPlugin,
      ],
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: "",
        // right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      customButtons: {
        prev: {
          click: () => {
            refCalendar.value.getApi().prev();
            dateCalendarFilter.value = refCalendar.value.getApi().getDate();
            controlaFiltro();
            countClickPrevNext.value++;
          },
        },
        next: {
          click: () => {
            refCalendar.value.getApi().next();
            dateCalendarFilter.value = refCalendar.value.getApi().getDate();
            controlaFiltro();
            countClickPrevNext.value--;
          },
        },
      },
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday

        startTime: "08:00", // a start time (10am in this example)
        endTime: "18:20", // an end time (6pm in this example)
      },
      resourceOrder: "nome",
      slotMinTime: "08:00:00",
      slotMaxTime: "13:00:00",
      allDaySlot: false,
      locale: "pt-br",
      scrollTime: moment().subtract(2.25, "hours").format("HH:mm"),
      initialDate: TODAY,
      // snapDuration: "00:10",
      slotDuration: "00:30",
      initialView: "resourceTimeGridDay",
      eventColor: "none",
      eventBorderColor: "none",
      slotEventOverlap: false,
      eventMaxStack: 2,
      height: "100%",
      contentHeight: "auto",
      eventBackgroundColor: "none",
      events: events.value,
      resources: resources.value,
      eventDurationEditable: false,
      resourceAreaWidth: "10%",
      views: {
        resourceTimeGridDay: {
          buttonText: "dia",
        },
        // timeGridWeek: { buttonText: "semana" },
        // dayGridMonth: { buttonText: "mês" },
      },
      editable: false,
      dayMaxEvents: true, // allow "more" link when too many events
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      resourceLabelContent: function (info) {
        const propriedades = info.resource.extendedProps;
        const html = `<div class="d-flex flex-column text-center align-items-center"><div><img class="w-50px h-50px" style="border-radius: 10px;" src="${propriedades.foto}"></div> <div style="color: #000">${propriedades.nome}</div></div>`;
        return { html };
      },
      eventClick: (info) => openVisualizarAgendamentoModal(info),
      // eventDrop: (info) => editarDropEvent(info),
      // eventClick: (info) => openVisualizarServicoModal(info),
      eventContent: function (info, createElement) {
        const { extendedProps } = info.event;
        let html = "";

        if (extendedProps.tipoCod == 1) {
          html = `
            <div class="consultor-event flex-column align-items-center py-0 fs-6 pointer" style="border-color: ${
              extendedProps.statusColor ?? "#fff"
            }">
              <div class="w-100 m-0 text-center text-white" style="background-color: ${
                extendedProps.statusColor ?? "#fff"
              }"> ${extendedProps.title} </div>
              <div class="w-100 h-100 d-flex justify-content-around align-items-center px-10" style="max-width: 400px">
                <span class="text-truncate text-center" style="width: 30%;">${
                  extendedProps.placa
                    ? replacePlaca(extendedProps.placa)
                    : cutChassi(extendedProps.chassi, -4, "...")
                }</span>
                <span class="text-truncate text-center" style="width: 25%">${trataNome(
                  extendedProps.cliente
                )}</span>
                <span class="text-truncate text-center" style="width: 30%">${
                  extendedProps.modelo
                }</span>
              </div>
            </div>
            `;
        } else if (extendedProps.tipoCod == 2) {
          const htmlIconTipo = extendedProps.passante
            ? "fas fa-car-side"
            : "far fa-clock";
          html = `
          <div class="consultor-event flex-column align-items-center py-0 fs-6" style="border-color: ${
            extendedProps.statusColor ?? "#fff"
          }">
            <div class="w-100 h-100 text-truncate m-0 text-center text-white position-relative" style="background-color: ${
              extendedProps.statusColor ?? "#fff"
            }"> 
              ${extendedProps.title}
              <span class="position-absolute" style="margin-left: 10px">
                <i class="${htmlIconTipo} text-white"></i>
              </span>
            </div>
            <div class="w-100 h-100 d-flex justify-content-around align-items-center px-10" style="max-width: 400px">
              <span class="text-truncate text-center" style="width: 30%;">${
                extendedProps.placa
                  ? replacePlaca(extendedProps.placa)
                  : cutChassi(extendedProps.chassi, -4, "...")
              }</span>
              <span class="text-truncate text-center" style="width: 25%">${trataNome(
                extendedProps.cliente
              )}</span>
              <span class="text-truncate text-center" style="width: 30%">${
                extendedProps.modelo
              }</span>
            </div>
          </div>
          `;
        }

        return { html };
      },
    });

    const refIntervals = reactive({
      baseAgendamento: 0,
      timeNow: 0,
    });

    const lastUpdatedAgendamentos: any = ref(null);

    // LIFECYCLE HOOKS
    onMounted(() => {
      horariosQuadro();
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;

      if (store.getters.consultoresList.length > 0) {
        consultores.value = store.getters.consultoresList;
        trataConsultores();
      }

      startPage();

      // EMITTER HOOKS
      emitter.on("update-agenda-agendamentos", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      refIntervals.timeNow = setInterval(() => {
        timeNow.value = moment().format("HH:mm:ss");
      }, 1000);

      // TODO Tranformar em socket
      inactivityTime();
    });

    store.watch(
      () => store.getters.concessionariasList,
      () => {
        if (store.getters.concessionariasList) horariosQuadro();
      }
    );

    onBeforeUnmount(() => {
      if (refIntervals.timeNow) {
        clearInterval(refIntervals.timeNow);
      }
      clearAllSetInterval();

      emitter.off("update-agenda-agendamentos");
      emitter.off("agenda-clear-interval");
    });

    // WATCH HOOKS
    store.watch(
      () => store.getters.concessionariaSelectedInfo,
      () => {
        concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      },
      { deep: true }
    );

    store.watch(
      () => store.getters.consultoresList,
      () => {
        consultores.value = store.getters.consultoresList;

        trataConsultores();
      },
      { deep: true }
    );

    store.watch(
      () => store.getters.baseAgendamentosList,
      () => {
        const auxMomentDate = moment(refCalendar.value.getApi().getDate());

        if (auxMomentDate.isSame(moment(), "day")) {
          clearAllSetInterval();
          loading.value = false;

          refIntervals.baseAgendamento = setInterval(() => {
            store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
          }, 300000);

          buscaTodosAgendamentos();
        }
      },
      { deep: true }
    );

    // FUNCTIONS
    function startPage() {
      window.dispatchEvent(new Event("resize"));
      inactivityTime();

      const auxDateSelected = moment(refCalendar.value?.getApi()?.getDate() ?? undefined);
      const auxUpdateTimeAgendamento =
        store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      clearAllSetInterval();

      if (auxDateSelected.isSame(moment(), "day")) {
        //  TODO Adicionar a lógica quando o período for diferente ao implementar base de OS abertas
        if (
          !auxUpdateTimeAgendamento ||
          moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))
        ) {
          // auxUpdateTimeAgendamento.value = moment();
          loading.value = true;

          store.dispatch(Actions.SET_BASE_AGENDAMENTOS).then(() => {
            loading.value = false;
          });
        } else {
          buscaTodosAgendamentos(true);
        }

        refIntervals.baseAgendamento = setInterval(() => {
          store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        }, 300000);
      } else {
        if (
          !lastUpdatedAgendamentos.value ||
          moment().isAfter(lastUpdatedAgendamentos.value.clone().add(210, "seconds"))
        ) {
          buscaTodosAgendamentos(true);

          refIntervals.baseAgendamento = setInterval(() => {
            buscaTodosAgendamentos();
          }, 300000);
        }
      }
    }

    function openVisualizarAgendamentoModal(info) {
      removeModalBackdrop();

      if (document.fullscreenElement) {
        document.exitFullscreen();
      }

      eventInfo.value = info.event?.extendedProps;
      const modal = new Modal(
        document.getElementById("kt_modal_hyundai_agendamento_info")
      );
      modal.show();
    }

    async function controlaFiltro() {
      clearAllSetInterval();

      if (moment(refCalendar.value.getApi().getDate()).isSame(moment(), "day")) {
        loading.value = true;
        await store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        loading.value = false;

        refIntervals.baseAgendamento = setInterval(() => {
          store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        }, 300000);
      } else {
        buscaTodosAgendamentos(true);

        refIntervals.baseAgendamento = setInterval(() => {
          buscaTodosAgendamentos();
        }, 300000);
      }
    }

    async function buscaTodosAgendamentos(showTransition = false) {
      updateVisibleTime();
      changeStoppedDayUpdate(countClickPrevNext.value, refCalendar.value, controlaFiltro);

      const diferencaDias: any =
        moment(refCalendar.value.getApi().getDate())
          .startOf("day")
          .diff(moment().startOf("day"), "days", true)
          .toFixed(0) ?? 0;

      let responseAgendamentos = [];
      if (diferencaDias == 0) {
        responseAgendamentos = store.getters.baseAgendamentosList;
      } else {
        loading.value = true;
        responseAgendamentos = await getBaseAgendamento(
          concessionariaInfo.value.codConcessionaria,
          diferencaDias
        )
          .then((data) => data)
          .catch((e) => {
            return [];
          });
        loading.value = false;
      }

      if (showTransition || loading.value) {
        if (responseAgendamentos.length > 0) {
          cacheAgendamentos.value = [...responseAgendamentos];
          await trataAgendamentos(responseAgendamentos);
        } else {
          await trataAgendamentos(cacheAgendamentos.value);
        }

        setTimeout(async () => {
          let responseAgendamentosEntrega = [];
          // Verifica se passou pelo menos 5 minutos desde a última requisição
          const afterFiveMinutes = moment(auxLastGetAgendamentoEntrega.value)
            .clone()
            .add(5, "minutes");
          const ableRequest = afterFiveMinutes.isAfter(moment());
          if (ableRequest) {
            auxLastGetAgendamentoEntrega.value = moment().format();
            responseAgendamentosEntrega = await getBaseAgendamentoEntrega(
              concessionariaInfo.value.codConcessionaria,
              diferencaDias
            )
              .then((data) => data)
              .catch((e) => {
                return [];
              });
          }

          if (responseAgendamentosEntrega.length > 0) {
            cacheAgendamentosEntregues.value = [...responseAgendamentosEntrega];
            trataAgendamentosEntregues(responseAgendamentosEntrega);
          } else {
            trataAgendamentosEntregues(cacheAgendamentosEntregues.value);
          }
        }, 10000);
      } else {
        setTimeout(async () => {
          if (responseAgendamentos.length > 0) {
            cacheAgendamentos.value = [...responseAgendamentos];
            await trataAgendamentos(responseAgendamentos);
          } else {
            await trataAgendamentos(cacheAgendamentos.value);
          }
          let responseAgendamentosEntrega = [];
          // Verifica se passou pelo menos 5 minutos desde a última requisição
          const afterFiveMinutes = moment(auxLastGetAgendamentoEntrega.value)
            .clone()
            .add(5, "minutes");
          const ableRequest = afterFiveMinutes.isAfter(moment());
          if (ableRequest) {
            auxLastGetAgendamentoEntrega.value = moment().format();
            responseAgendamentosEntrega = await getBaseAgendamentoEntrega(
              concessionariaInfo.value.codConcessionaria,
              diferencaDias
            )
              .then((data) => data)
              .catch((e) => {
                return [];
              });
          }

          if (responseAgendamentosEntrega.length > 0) {
            cacheAgendamentosEntregues.value = [...responseAgendamentosEntrega];
            trataAgendamentosEntregues(responseAgendamentosEntrega);
          } else {
            trataAgendamentosEntregues(cacheAgendamentosEntregues.value);
          }
        }, 10000);
      }
    }

    function clearAllSetInterval() {
      clearInterval(refIntervals.baseAgendamento);
    }

    function inactivityTime() {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000);
      }
    }

    function changeCalendarDate(date) {
      if (date) {
        refCalendar.value.getApi().gotoDate(date);
        buscaTodosAgendamentos();
      }
    }

    async function trataConsultores() {
      while (resources.value.length) {
        resources.value.pop();
      }

      consultores.value.forEach((consultor, index) => {
        const resource = {
          id: Number(consultor.codConsultor),
          title: consultor.nome,
          eventColor: "none",
          eventBackgroundColor: "none",
          eventBorderColor: "none",
          extendedProps: {
            nome: consultor.nome,
            foto: consultor.imagem,
            color: consultor.corAgenda,
            servico: "",
            num: index + 1,
            status: "Plano",
          },
        };

        resources.value.push(resource);
      });
    }

    function getCodConsultor(nomeConsultor) {
      if (!nomeConsultor) {
        return 0;
      }

      let codConsultor = consultores.value.find((consultor) => {
        return (
          removeAcentos(consultor.intConsultor, true) ==
            removeAcentos(nomeConsultor, true) ||
          consultor.intConsultorInterno == nomeConsultor
        );
      })?.codConsultor;

      if (!codConsultor) {
        codConsultor = consultores.value.find((consultor) => {
          return (
            removeAcentos(consultor.nome, true) == removeAcentos(nomeConsultor, true)
          );
        })?.codConsultor;
      }

      return codConsultor ?? 0;
    }

    function clearCalendar() {
      const eventsCalendar = refCalendar.value?.getApi()?.getEvents() ?? [];
      eventsCalendar.forEach((event) => {
        event.remove();
      });
    }

    function removeAcentos(name, toLowerCase = false) {
      if (!name) {
        return "";
      }
      const stringTratado = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    }

    function updateVisibleTime(value = "0", setLocalStorage = true) {
      if (value == "0") {
        const cacheVisibleType: any = window.localStorage.getItem(
          "agendamentoVisibleType"
        );

        updateVisibleTime(cacheVisibleType ?? "1");
        return;
      }

      if (setLocalStorage) {
        window.localStorage.setItem("agendamentoVisibleType", value);
      }
      // AUTOMATICO
      if (value == "1") {
        const dayFormat = moment().format("YYYY-MM-DD");

        moment(`${dayFormat} 13:00`).diff(moment()) > 0
          ? updateVisibleTime("2", false)
          : updateVisibleTime("3", false);
        return;
      }

      // MANHÃ
      if (value == "2") {
        refCalendar.value
          ?.getApi()
          .setOption("slotMinTime", moment(horaAbertura.value, "H").format("HH:mm:ss"));
        refCalendar.value?.getApi().setOption("slotMaxTime", "13:00:00");

        return;
      }
      // TARDE
      if (value == "3") {
        refCalendar.value?.getApi().setOption("slotMinTime", "13:00:00");
        refCalendar.value
          ?.getApi()
          .setOption("slotMaxTime", moment(horaFechamento.value, "H").format("HH:20:ss"));

        return;
      }
      // FULL
      if (value == "4") {
        refCalendar.value
          ?.getApi()
          .setOption("slotMinTime", moment(horaAbertura.value, "H").format("HH:mm:ss"));
        refCalendar.value
          ?.getApi()
          .setOption("slotMaxTime", moment(horaFechamento.value, "H").format("HH:20:ss"));

        return;
      }
    }

    async function trataAgendamentos(response) {
      let regexMarca: any;

      clearCalendar();
      response.forEach((agendamento) => {
        const dataAgendamento = moment(
          `${agendamento.dataagendamento} ${agendamento.horaagendada}`,
          "DD/MM/YYYY HH:mm"
        );

        let status = 0;
        if (agendamento.osaberta) {
          status = 2;
        } else if (
          dataAgendamento.clone().add(18, "minutes").diff(moment(), "minutes") < 0
        ) {
          status = 1;
        }

        regexMarca = agendamento.marca
          ? new RegExp("(" + agendamento.marca + ")", "gi")
          : "";

        let title = "";
        if (status == 0) {
          title = `Agendamento - ${dataAgendamento.clone().format("HH:mm")}`;
        } else if (status == 1) {
          title = `Atrasado - ${dataAgendamento.clone().format("HH:mm")}`;
        } else if (status == 2) {
          title = `Recebido - OS ${agendamento.osaberta}`;
        }

        const newEvent = {
          id: agendamento.idagendamento,
          resourceId: getCodConsultor(
            agendamento.consultorcodigo ?? agendamento.consultor_agendado
          ),
          start: dataAgendamento.format(),
          end: dataAgendamento.clone().add(20, "minutes").format(),
          extendedProps: {
            inicio: dataAgendamento.clone().format("HH:mm"),
            fim: dataAgendamento.clone().add(40, "minutes").format("HH:mm"),
            ...agendamento,
            tipoCod: 1,
            statusName: refStatus[status].nome,
            statusColor: refStatus[status].cor,
            status,
            title,
            tipo: "Agendamento",
            cliente: trataNome(agendamento.cliente),
            consultor: trataNome(agendamento.consultor_agendado),
            modelo: agendamento.modelo ? agendamento.modelo.replace(regexMarca, "") : "",
          },
        };

        refCalendar.value.getApi().addEvent(newEvent);
      });

      refRegexMarca.value = regexMarca;
    }

    async function trataAgendamentosEntregues(response) {
      response.forEach((agendamentoEntrega) => {
        const dataAgendamentoEntrega = moment(
          agendamentoEntrega.osdataprometidaentrega,
          "YYYY-MM-DD HH:mm:ss"
        );

        const title = `Entrega - ${dataAgendamentoEntrega.clone().format("HH:mm")} - OS ${
          agendamentoEntrega.osnumero ?? ""
        }`;

        const newEvent = {
          id: `${agendamentoEntrega.idagendamento}_entrega`,
          resourceId: getCodConsultor(
            agendamentoEntrega.consultorcodigo ?? agendamentoEntrega.consultornome
          ),
          start: dataAgendamentoEntrega.format(),
          end: dataAgendamentoEntrega.clone().add(19, "minutes").format(),
          extendedProps: {
            inicio: dataAgendamentoEntrega.clone().format("HH:mm"),
            fim: dataAgendamentoEntrega.clone().add(20, "minutes").format("HH:mm"),
            ...agendamentoEntrega,
            statusName: "",
            statusColor: "#173872",
            status: "",
            tipoCod: 2,
            tipo: "Entrega Planejada",
            title,
            agendamentoNoMesmoHorario: false,
            cliente: trataNome(agendamentoEntrega.cliente ?? ""),
            consultor: trataNome(agendamentoEntrega.consultornome),
            modelo: agendamentoEntrega.modelo
              ? agendamentoEntrega.modelo.replace(refRegexMarca.value, "")
              : "",
            passante: !agendamentoEntrega.idagendamento,
          },
        };

        refCalendar.value.getApi().addEvent(newEvent);
      });
    }

    function calendarFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }
      let elem: any = document.querySelector("#card-calendar-hyundai-consultor");
      if (elem.requestFullscreen) {
        elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen({ navigationUI: "show" });
      }
    }

    function trataNome(nome) {
      if (!nome || nome == "undefined" || nome == undefined) {
        return "";
      }
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}`;

      return nomeTratado;
    }

    return {
      ptBr,
      calendarOptions,
      events,
      refCalendar,
      resources,
      eventInfo,
      calendarFullscreen,
      loading,
      concessionariaInfo,
      codNivel,
      consultores,
      timeNow,
      isUserActive,
      refStatus,
      agendamentosEntreguesConflitantes,
      updateVisibleTime,
      visibleTime,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      dateCalendarFilter,
      changeCalendarDate,
    };
  },
});
